#navbar {
  background-color: rgba(241,249,248,.9);
  margin-bottom: 10px;
  width: 100%;
}




body {
  font-family: "Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  background-color: #000000
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  font-family: "Josefin Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
}

p {
  font-size: 1.25em;
  line-height: 1.6;
  color: #000;
}

hr {
  max-width: 400px;
  border-color: #999999;
}

.brand,
.address-bar {
  display: none;
}


.img-full {
  min-width: 100%;
}

.brand-before,
.brand-name {
  text-transform: capitalize;
}

.brand-before {
  margin: 15px 0;
}

.brand-name {
  margin: 0;
  font-size: 4em;
}

.tagline-divider {
  margin: 15px auto 3px;
  max-width: 250px;
  border-color: #999999;
}

.box {
  margin-bottom: 20px;
  padding: 10px 15px;
  background: #fff;
  background: rgba(241,249,248,0.9);
}

.intro-text {
  text-transform: uppercase;
  font-size: 1.25em;
  font-weight: 400;
  letter-spacing: 1px;
}

.img-border {
  float: none;
  margin: 0 auto 0;
  border: #999999 solid 1px;
}

.img-left {
  float: none;
  margin: 0 auto 0;
}

footer {
  background: #fff;
  background: rgba(241,249,248,0.9);
}

footer p {
  margin: 0;
  padding: 5px 0;
}

@media screen and (min-width:768px) {
  .brand {
      display: inherit;
      margin: 0;
      padding: 30px 0 10px;
      text-align: center;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
      font-family: "Josefin Slab","Helvetica Neue",Helvetica,Arial,sans-serif;
      font-size: 5em;
      font-weight: 700;
      line-height: normal;
      color: #fff;
  }

  .top-divider {
      margin-top: 0;
  }

  .img-left {
      float: left;
      margin-right: 25px;
  }

  .address-bar {
      display: inherit;
      margin: 0;
      padding: 0 0 10px;
      text-align: center;
      text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
      text-transform: uppercase;
      font-size: 1.25em;
      font-weight: 400;
      letter-spacing: 3px;
      color: #fff;
  }

}


@media screen and (min-width:994px) {
  .navbar-nav {
    margin: 0;
    display: table;
    width: 100%;
  }

  .navbar-nav li {
    display: table-cell;
    width: 1%;
    float: none;
    text-align: center;
  }

  .navbar-nav li a {
    color: #777;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 3px;
    font-weight: 700;
    font-size: 18px;
  }

  .navbar-nav li a:hover {
    color: black;
    font-weight: bold;
    text-decoration: none;
  }
}



@media screen and (min-width:1200px) {
  .box:after {
      content: '';
      display: table;
      clear: both;
  }
}

.glyphicon.normal-right-spinner {
  -webkit-animation: glyphicon-spin-r 2s infinite linear;
  animation: glyphicon-spin-r 2s infinite linear;
}


@-webkit-keyframes glyphicon-spin-r {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

@keyframes glyphicon-spin-r {
  0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
  }

  100% {
      -webkit-transform: rotate(359deg);
      transform: rotate(359deg);
  }
}

.curriculum-link {
  cursor: pointer;
}

.techniques > div > p {
  font-size: 14px;
}

.knowledge-item {
  list-style: none;
  margin-top: 10px;
}

.knowledge-item-link {
  font-weight: bold;
  color: #337ab7;
  font-size: 1.25em;
}

.schedule-day{
  list-style: none;
  margin-top: 5px;
  font-size: 24px;
  font-weight: bold;
}

.schedule-item{
  list-style: none;
  margin-top: 5px;
  font-size: 16px;
}

.schedule-item a{
  color: #337ab7;
  font-size: 1.25em;
}

.fake-link {
  color: #337ab7;
  cursor: pointer;
}